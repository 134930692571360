/* ContactUs.css */
.contact-hero-section {
    position: relative;
  }
  
  .contact-hero-section img {
    height: 500px;
    object-fit: cover;
  }
  
  .contact-hero-section .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .contact-hero-section h1 {
    font-size: 3rem;
    font-weight: bold;
  }
  
  .nav-link.active {
    font-weight: bold;
    border-bottom: 2px solid #007bff;
  }
  
  .bi {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  .list2{
    list-style-type: none !important;

  }
  .terms-text1{
    text-align: left;
    font-size: 24px;
  }
  .terms-text-para1{
    text-align: left;
    font-size: 17px;
    color: black;
  }