.url-shortener-wrapper {
    background-color: #9e3030;
    min-height: 100vh;
    padding-top: 50px;
  }
  
  h1 {
    font-size: 2rem;
    font-weight: bold;
  }
  
  p {
    font-size: 1rem;
    color: #555;
  }
  
  .highlight {
    background-color: #ffecb3;
  }
  
  .cta-button {
    padding: 10px 20px;
    font-size: 1rem;
  }
  
  .url-input {
    border-radius: 0;
  }
  
  .shorten-button {
    border-radius: 0;
  }
  
  .shortened-url-section {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .shortened-url-section img {
    display: inline-block;
  }
  
  .shortened-url-section a {
    font-weight: bold;
    color: #000;
    text-decoration: none;
  }
  
  .note {
    font-size: 0.9rem;
  }
  .options-popup {
    position: absolute;
    top: 100%;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;
    width: 120px;
  }
  
  .options-popup button {
    display: block;
    width: 100%;
    text-align: center;
    padding: 5px 10px;
    color: red;
    font-size: 14px;
  }
  
  .options-popup button:hover {
    background: #f5f5f5;
  }
  .nav-link {
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 5px; /* Add spacing between tabs */
    transition: all 0.3s ease; /* Optional for smooth hover effect */
  }
  
  .nav-link.active {
    font-weight: bold;
    color: #007bff !important;
    border-bottom: 2px solid #007bff;
  }
  
  .nav-link.notactive {
    font-weight: bold;
    color: black !important;
  }
  
  .navbar .mx-auto {
    display: flex;
    justify-content: center; /* Center-align tabs */
  }